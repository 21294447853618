<template>
  <div class="Material">
    <simple-c-u-r-d
      ref="Material"
      search-url="/api/material/list"
      delete-url="/api/material/delete"
      :show-form="showForm"
      :format-search-param="formatSearchParam"
      :show-operation="()=>roleName!=='施工人员'"
      is-pageable
    >
      <template #searchOperation>
        <el-button v-if="roleName!=='施工人员'&&deleteAllButton" type="primary" plain size="medium" @click="deleteAll">
          删除全部
        </el-button>
      </template>
    </simple-c-u-r-d>
  </div>
</template>

<script>
import SimpleCURD from '@/components/simple/SimpleCURD'
import ArrayUtils from '@/common/js/ArrayUtils'

export default {
  name: 'Material',
  inject: ['user'],
  components: { SimpleCURD },
  data() {
    return {
      showForm: [
        {
          param: 'projectId',
          name: '项目名称',
          clearable: true,
          search: true,
          filterable: true,
          type: 'select',
          option: () => {
            return this.user().projectList.map(item => {
              return {
                label: item.name,
                value: item.id,
              }
            })
          },
          formatter: (row) => ArrayUtils.getArrayObject(this.user().projectList, 'id', row.projectId)?.name
        },
        { param: 'serialNo', name: '设备序列号', search: true, clearable: true },
        { param: 'constructionDevice', name: '设备名称', clearable: true },
      ],
      deleteAllButton: false,
      roleName: ''
    }
  },
  mounted() {
    this.$watch(() => this.$refs.Material.searchAddForm.projectId, () => {
      if (this.$refs.Material.searchAddForm.projectId) {
        this.deleteAllButton = true
      } else {
        this.deleteAllButton = false
      }
      this.$refs.Material.search()
    })
  },
  created() {
    this.roleName = this.user().account.roleName
  },
  methods: {
    formatSearchParam(param) {
      // if (!param.projectId) {
      //   param.projectList = [...this.user().projectList.map(item => item.id)]
      // }
      return param
    },
    deleteAll() {
      this.$confirm(`确认删除${ArrayUtils.getArrayObject(this.user().projectList, 'id', this.$refs.Material.searchAddForm.projectId).name}项目的设备信息吗`, '确认删除').then(() => {
        this.$get('/api/material/deleteAll?projectId=' + this.$refs.Material.searchAddForm.projectId).then(() => this.$refs.Material.search())
      })
    }
  }
}
</script>

<style scoped>

</style>
